import React, { useState, useEffect, lazy } from "react"
import { Container, Row, Col } from "react-bootstrap";
import SEO from "../components/seo";
import { navigate, Link } from "gatsby";
import { useSearchParams, useParams } from "react-router-dom";

import * as stylesIndex from "../styles/styles-home.module.css";

import btnBuick from "../images/Nuevo/Principal/b_buick.png";
import btnCadillac from "../images/Nuevo/Principal/b_cadillac.png";
import btnGMC from "../images/Nuevo/Principal/b_gmc.png";

import btnChev from "../images/Nuevo/Principal/b_chevrolet.png";
import btnBuickMovil from "../images/Nuevo/Principal/b_buick_movil.png";
import btnCadillacMovil from "../images/Nuevo/Principal/b_cadillac_movil.png";
import btnGMCMovil from "../images/Nuevo/Principal/b_gmc_movil.png";

///////////////////// HOME PAGE //////////////////////

const LayoutComponent = lazy(() => import('../components/layouts/layoutHome'));
const Image = lazy(() => import("../components/widgets/basico/image"));
const ImageMovil = lazy(() => import("../components/widgets/basico/imageMovil"));
 
const IndexPage = (props) => {
  const parameters = props.location.search; 

  function navegante(marca){
    navigate(`https://chevroletrivero.com/${marca.toLowerCase()}/catalogo/nuevos/` + parameters,{state: {marcaHome: marca}});
  }

  return (
    <LayoutComponent parameters={parameters}>
      <SEO
        title="Chevrolet Rivero Monterrey - Agencia Autos Nuevos y Seminuevos"
        description="Grupo Automotriz - Agencias Chevrolet Monterrey. Apoyamos a la gente de manera transparente, para que estrenes tu carro nuevo o seminuevo. Chevrolet, Cadillac, Buick, GMC. Aveo, Aveo HB, Beat, Onix, Tracker"
        keywords="Chevrolet Rivero, agencia chevrolet, autos seminuevos, carro nuevo, carro chevrolet, estrena carro"
      />
      <div className={stylesIndex.fullContain}>
        <Container fluid className="d-lg-block d-md-block d-none p-0" >
   
          <div className="d-lg-flex d-md-flex d-none" style={{ position: "relative" }} >
              <div  style={{height: '100%', width: '100%' }}>
                  <Image
                      fileName={`back.jpg`}
                      alt="home banner chevrolet"
                      imgStyles={{ width: "100%", height: "100vh" }}
                      style={{ width: "100%", height: "100vh" }}
              /> 
              </div>
            <div className={stylesIndex.containerDesktop}>
              <Container className="pt-5">
                <div style={{height: 75}} className={stylesIndex.todosSomosPrimos}>
{/*                   <Image
                      fileName={`img_todosprimos`}
                      alt="home todos somos primos chevrolet"
                      imgStyles={{ width: "50%", height: 75, objectFit: 'contain'}}
                      style={{ width: "50%", height: 75 }}
                  /> */}
                </div>
              </Container>
              <Container>
               {<>
               <Row>
                <Col lg={6} md={6} sm={6} xs={6}>
                  <Row>
                    <h1 className="slogan">¡HOY ES EL DÍA DE</h1>
                    <h1 className="slogan">ESTRENAR TU</h1>
                    <h1 className="slogan">NUEVA GROOVE!</h1>
                  </Row>
                </Col>
                <Col lg={6} md={6} sm={6} xs={6}>
                  <Row>
                    <h1 className="da-click">¡ESTRENA UN AUTO NUEVO!</h1>
                    <h4 style={{color: 'white', textShadow:' 2px 4px 4px black', fontSize: 15, textAlign: 'center'}}>DA CLIC EN TU MARCA FAVORITA</h4>
                    <Link id="home-button-chevrolet" to={parameters ? "/chevrolet/catalogo/nuevos" + parameters : "/chevrolet/catalogo/nuevos"}>
                      <div className={stylesIndex.nuevoCarro} style={{ height: 100}}>
                        <Image
                            fileName={`b_chevrolet`}
                            alt="chevrolet rivero home boton"
                            imgStyles={{ width: "70%", height: "70%", marginLeft: '15%'}}
                        />  
                      </div>
                    </Link>
                  </Row>
                </Col>
               </Row>

               <Row>
                <Col lg={6} md={6} sm={6} xs={6}>
                  <div className={stylesIndex.mensualidadDesde}>
                    Mensualidad desde : $4,999
                  </div>
                </Col>
                <Col lg={6} md={6} sm={6} xs={6}>
                  <div style={{display: 'flex', alignItems: 'center', height: '100%'}}>
                      <Link id="home-button-buick" className={stylesIndex.nuevoCarro} style={{height: 70, width: '100%'}} to={parameters ? "/buick/catalogo/nuevos" + parameters : "/buick/catalogo/nuevos"}>
                          <Image
                              fileName={`b_buick_movil`}
                              alt="home boton nuevos buick"
                              imgStyles={{ width: "100%", height: "100%", objectFit: 'contain'}}
                              style={{ width: "100%", height: "100%" }}
                          />  
                      </Link>
                      <Link id="home-button-cadillac" className={stylesIndex.nuevoCarro} style={{height: 70, width: '100%'}} to={parameters ? "/cadillac/catalogo/nuevos" + parameters : "/cadillac/catalogo/nuevos"}>
                          <Image
                              fileName={`b_cadillac_movil`}
                              alt="home boton nuevos cadillac"
                              imgStyles={{ width: "100%", height: "100%", objectFit: 'contain'}}
                              style={{ width: "100%", height: "100%" }}
                          />  
                      </Link>
                      <Link id="home-button-gmc" className={stylesIndex.nuevoCarro} style={{height: 70, width: '100%'}} to={parameters ? "/gmc/catalogo/nuevos" + parameters : "/gmc/catalogo/nuevos"}>
                          <Image
                              fileName={`b_gmc_movil`}
                              alt="home boton nuevos gmc"
                              imgStyles={{ width: "100%", height: "100%", objectFit: 'contain'}}
                              style={{ width: "100%", height: "100%" }}
                          />  
                      </Link>
                    </div>
                </Col>
               </Row>

                </>}
                <Row className="mt-3 mb-5">
                  <Col lg={6} md={6} sm={6} xs={6}>
                    <div style={{position: 'relative', display: 'flex', zIndex: 1, alignItems: 'center'}} className={stylesIndex.bannerTiraSeminuevos}>
                        <Image
                            fileName={`img_seminuevos.jpg`}
                            alt="home boton seminuevos chevrolet"
                            imgStyles={{ width: "100%", height: "100%", objectFit: 'contain'}}
                            style={{ width: "100%", height: "100%" }}
                        />
                        <div style={{position: 'absolute'}}  className="d-md-flex">
                            <center className="mx-5">
                              <Link id="home-button-seminuevos" to={"/catalogo/seminuevos/" + parameters}>
                                <div className="grupoBTNSemis m-1 pb-1">
                                  <Image
                                    fileName="b_ver_seminuevos.png"
                                    alt="Home boton seminuevos"
                                  />
                                </div>
                              </Link>
                              <Link id="home-button-valua-carro" to={"/valua-tu-carro/" + parameters}>
                                <div className="m-1 grupoBTNSemis" >
                                  <Image
                                    fileName="b_vende.png"
                                    alt="Home boton vende tu auto"
                                  />
                                </div>
                              </Link>
                            </center>
                        </div>
                    </div>
                  </Col>

                  <Col lg={6} md={6} sm={6} xs={6}>
                  <Link id="home-button-servicio" to={"/taller-de-servicio/" + parameters}>
                    <div style={{position: 'relative', display: 'flex', zIndex: 1, alignItems: 'center'}} className={stylesIndex.bannerTiraTallerServicio}>
                      
                        <Image
                            fileName={`img_taller.jpg`}
                            alt="home boton taller servicio chevrolet"
                            imgStyles={{ width: "100%", height: "100%", objectFit: 'contain'}}
                            style={{ width: "100%", height: "100%" }}
                        />
                       
                    </div>
                    </Link>
                  </Col>

                  <Col lg={6} md={6} sm={6} xs={6}>
                    <div className={stylesIndex.bannerTiraTallerServicio}>
                      <Link to={"/taller-de-servicio/" + parameters}>
                       
                      </Link>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </Container>

        <Container className="d-lg-none d-md-none d-flex px-0">
          <div className={stylesIndex.containerZoomMovil}>
            <div className="zoom-in-zoom-out">
              <ImageMovil
                fileName={`f-movil-2`}
                alt="banner Image Home"
                style={{height: '100%'}}
              />
            </div>
          </div>
          <Container className={stylesIndex.containerMovil}>
              {<>
              <div>
                <Row>
                   <h1 className="slogan-movil">¡HOY ES EL DÍA DE</h1>
                   <h1 className="slogan-movil">ESTRENAR TU</h1>
                   <h1 className="slogan-movil">NUEVA GROOVE!</h1>
                </Row>
                <Row className="pt-1 pb-1">
                  <div className={stylesIndex.mensualidadDesde}>
                    Mensualidad desde : $4,999
                  </div>
                </Row>

{/*                 <Row>

                  <Col sm={12} className="pb-3">
                    <Link id="home-button-chevrolet" to={parameters ? "/chevrolet/catalogo/nuevos" + parameters : "/chevrolet/catalogo/nuevos"}>
                      <div className={stylesIndex.nuevoCarro} style={{height: 65}}>
                        <Image
                            fileName={`b_chevrolet`}
                            alt="home boton nuevos chevrolet"
                            imgStyles={{ width: 190, height: "100%", objectFit: 'contain'}}
                            style={{ width: "100%", height: "100%" }}
                        />  
                      </div>
                    </Link>
                  </Col>
                  
                  <Col sm={12}>
                    <div style={{display: 'flex', alignItems: 'center', height: '80%'}}>
                      <Link id="home-button-buick" className={stylesIndex.nuevoCarro} style={{height: 65, width: '100%'}} to={parameters ? "/buick/catalogo/nuevos" + parameters : "/buick/catalogo/nuevos"}>
                          <Image
                              fileName={`b_buick_movil`}
                              alt="home boton nuevos buick"
                              imgStyles={{ width: "100%", height: "100%", objectFit: 'contain'}}
                              style={{ width: "100%", height: "100%" }}
                          />  
                      </Link>
                      <Link id="home-button-cadillac" className={stylesIndex.nuevoCarro} style={{height: 65, width: '100%'}} to={parameters ? "/cadillac/catalogo/nuevos" + parameters : "/cadillac/catalogo/nuevos"}>
                          <Image
                              fileName={`b_cadillac_movil`}
                              alt="home boton nuevos cadillac"
                              imgStyles={{ width: "100%", height: "100%", objectFit: 'contain'}}
                              style={{ width: "100%", height: "100%" }}
                          />  
                      </Link>
                      <Link id="home-button-gmc" className={stylesIndex.nuevoCarro} style={{height: 65, width: '100%'}} to={parameters ? "/gmc/catalogo/nuevos" + parameters : "/gmc/catalogo/nuevos"}>
                          <Image
                              fileName={`b_gmc_movil`}
                              alt="home boton nuevos gmc"
                              imgStyles={{ width: "100%", height: "100%", objectFit: 'contain'}}
                              style={{ width: "100%", height: "100%" }}
                          />  
                      </Link>
                    </div>
                  </Col>
                </Row> */}
              </div></>}
          </Container>
        </Container>

        <Container id="containerTirasMovil" className="d-lg-none d-md-none d-block">

          <Row className={stylesIndex.divTiraNuevos} style={{backgroundColor: '#0b5394'}}>
            <Col className="d-flex align-items-center" xs={6} sm={6} md={6} lg={6} xl={6}>
              <div>
              <h1 className={stylesIndex.textoTiras} style={{color:'#d68400'}}>¡ESTRENA UN AUTO</h1>
              <h1 className={stylesIndex.textoTiras2} style={{color:'#d68400'}}>NUEVO!</h1>
              </div>

            </Col>
            <Col lg={6} md={6} sm={6} xs={6}>
              <Row>
                <Col lg={9} md={9} sm={9} xs={9} className="dosBoton1">
                  <div className="banner-container-botones">
                    <center>
                      <div className="btn-chev-home pt-3 pb-3" onClick={() => navegante("CHEVROLET")}>
                        {" "}
                        <img className="btnChevHome" src={btnChev} />
                      </div>
                    </center>
                  </div>
                </Col>

                <Col lg={3} md={3} sm={3} xs={3} className="dosBoton2">
                  <div className="banner-container-botones">
                    <center>
                      <div className="btn-buick-home" onClick={() => navegante("BUICK")}>
                        <img className="btnOtrosHome" src={btnBuick} />
                      </div>
                    </center>
                    <center>
                      <div className="btn-cadillac-home" onClick={() => navegante("CADILLAC")}>
                        {" "}
                        <img className="btnOtrosHome" src={btnCadillac} />
                      </div>
                    </center>
                    <center>
                      <div className="btn-gmc-home" onClick={() => navegante("GMC")}>
                        {" "}
                        <img className="btnOtrosHome" src={btnGMC} />
                      </div>
                    </center>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className={stylesIndex.divTiraSeminuevos} style={{backgroundColor: '#011f36'}}>
            <Col className="d-flex align-items-center" xs={6} sm={6} md={6} lg={6} xl={6}>
              <div>
              <h1 className={stylesIndex.textoTiras} style={{color:'white'}}>¿BUSCAS UN CARRO</h1>
              <h1 className={stylesIndex.textoTiras2} style={{color:'white'}}>SEMINUEVO?</h1>
              </div>

            </Col>
            <Col lg={6} md={6} sm={6} xs={6}>
            <center>
                <div>
                  <Link id="home-button-seminuevos" to={"/catalogo/seminuevos/" + parameters}>
                    <div className="m-1">
{/*                       <Image
                        fileName="b_ver_seminuevos.png"
                        alt="Home boton seminuevos"
                      /> */}
                      <h4 className="boton-tiras-movil m-0 p-1" style={{backgroundColor: '#c27701', color: 'white'}}>Ver autos</h4>
                    </div>
                  </Link>
                  <Link id="home-button-valua-carro" to={"/valua-tu-carro/" + parameters}>
                    <div className=" m-1">
{/*                       <Image
                        fileName="b_vende.png"
                        alt="Home boton vende tu auto"
                      /> */}
                      <h4 className="boton-tiras-movil m-0 p-1" style={{backgroundColor: 'white', color: '#c27701'}}>Vende tu auto</h4>
                    </div>
                  </Link>
                </div>
              </center>
            </Col>
          </Row>

          <Row className={stylesIndex.divTiraTallerServicio} style={{backgroundColor: '#003765'}}>
            <Col className="d-flex align-items-center" xs={6} sm={6} md={6} lg={6} xl={6}>
              <div>
              <h1 className={stylesIndex.textoTiras} style={{color:'#c27701'}}>AGENDA TU CITA DE</h1>
              <h1 className={stylesIndex.textoTiras2} style={{color:'#c27701'}}>SERVICIO</h1>
              </div>

            </Col>
            <Col lg={6} md={6} sm={6} xs={6}>
            <center>
                <div>
                  <Link id="home-button-seminuevos" to={"/taller-de-servicio/" + parameters}>
                    <div className="mt-2">
{/*                       <Image
                        fileName="b_ver_seminuevos.png"
                        alt="Home boton seminuevos"
                      /> */}
                      <h4 className="boton-tiras-movil m-0 p-2" style={{backgroundColor: 'white', color: 'black'}}>Hacer cita</h4>
                    </div>
                  </Link>
                </div>
              </center>
            </Col>
          </Row>

{/*           <Row className={stylesIndex.divTiraSeminuevos}>
            <Col className="d-flex align-items-center" lg={6} md={6} sm={7} xs={7} >
              <div className={stylesIndex.tiraSeminuevosMovil}>
                <Image
                    fileName="tira_seminuevos_movil.png"
                    alt="home boton seminuevos chevrolet"
                    imgStyles={{ width: "100%", height: "100%", objectFit: 'contain'}}
                    style={{ width: "100%", height: "100%" }}
                  />
              </div>
            </Col>

            <Col lg={6} md={6} sm={5} xs={5}>
              <center>
                <div>
                  <Link id="home-button-seminuevos" to={"/catalogo/seminuevos/" + parameters}>
                    <div className="m-1">
                      <Image
                        fileName="b_ver_seminuevos.png"
                        alt="Home boton seminuevos"
                      />
                    </div>
                  </Link>
                  <Link id="home-button-valua-carro" to={"/valua-tu-carro/" + parameters}>
                    <div className=" m-1">
                      <Image
                        fileName="b_vende.png"
                        alt="Home boton vende tu auto"
                      />
                    </div>
                  </Link>
                </div>
              </center>
            </Col>
          </Row> */}

{/*           <Row className={stylesIndex.divTiraTallerServicio}>
            <Col className="d-flex align-items-center" lg={6} md={6} sm={7} xs={7} >
              <div className={stylesIndex.tiraSeminuevosMovil}>
                <Image
                    fileName="tira_taller_movil.png"
                    alt="home boton taller chevrolet"
                    imgStyles={{ width: "100%", height: "90%", objectFit: 'contain'}}
                    style={{ width: "100%", height: "90%" }}
                  />
              </div>
            </Col>

            <Col className="pt-2" lg={6} md={6} sm={5} xs={5}>
              <center>
                <div>
                  <Link id="home-button-seminuevos" to={"/taller-de-servicio/" + parameters}>
                    <div className="m-1">
                      <Image
                        fileName="b_cita.png"
                        alt="Home boton seminuevos"
                      />
                    </div>
                  </Link>
                </div>
              </center>
            </Col>
          </Row> */}
        </Container>
      </div>
    </LayoutComponent>
  );
} 

export default IndexPage

