// extracted by mini-css-extract-plugin
export var EntrarDesdeAbajo = "styles-home-module--EntrarDesdeAbajo--77a91";
export var EntrarDesdeArriba = "styles-home-module--EntrarDesdeArriba--0097d";
export var EntrarDesdeDer = "styles-home-module--EntrarDesdeDer--84146";
export var EntrarDesdeIzq = "styles-home-module--EntrarDesdeIzq--204be";
export var bannerContainerTiraSeminuevos = "styles-home-module--banner-container-tira-seminuevos--5ec4d";
export var bannerTiraSeminuevos = "styles-home-module--bannerTiraSeminuevos--36fa8";
export var bannerTiraTallerServicio = "styles-home-module--bannerTiraTallerServicio--a7d43";
export var containerDesktop = "styles-home-module--containerDesktop--308a9";
export var containerMovil = "styles-home-module--containerMovil--16f43";
export var containerZoomMovil = "styles-home-module--containerZoomMovil--6c425";
export var divTiraNuevos = "styles-home-module--divTiraNuevos--4fc6d";
export var divTiraSeminuevos = "styles-home-module--divTiraSeminuevos--1c727";
export var divTiraTallerServicio = "styles-home-module--divTiraTallerServicio--e5633";
export var fullContain = "styles-home-module--fullContain--4c59d";
export var mensualidadDesde = "styles-home-module--mensualidadDesde--772c9";
export var nuevoCarro = "styles-home-module--nuevoCarro--3e2b8";
export var semisMovil = "styles-home-module--semisMovil--b4575";
export var tallerMovil = "styles-home-module--tallerMovil--de179";
export var textoTiras = "styles-home-module--textoTiras--d8359";
export var textoTiras2 = "styles-home-module--textoTiras2--3411f";
export var tiraSeminuevosMovil = "styles-home-module--tiraSeminuevosMovil--3c22d";
export var tiraTallerServicioMovil = "styles-home-module--tiraTallerServicioMovil--bea21";
export var todosSomosPrimos = "styles-home-module--todosSomosPrimos--2eb76";